import React from "react"
import { splitArrayIntoChunksOfLen } from "./../../../util/helper"
import H2 from "../../core/H2"

export const TechStackCardBlock = ({ TechStackCardBlock, theme }) => {
  if (!theme) {
    theme = "#335fff"
  }
  const { title, techCard } = TechStackCardBlock
  const chunked = splitArrayIntoChunksOfLen(techCard, techCard.length / 3)
  const contentData = data => {
    return (
      <div className="px-4 py-3 flex" key={Math.random()}>
        <div className="bg-white border-solid border rounded border-gray-300 w-full">
          <div className="border-solid border-b border-gray-300 flex flex-row items-center py-3">
            {data.title && (
              <span
                className="h-6 w-6 ml-4 rounded-2xl text-white  flex justify-center text-base"
                style={{ backgroundColor: theme }}
              >
                {data.title.charAt(0).toUpperCase()}
              </span>
            )}
            {data.title && (
              <p className="text-base px-2 font-bold text-shark-500">
                {data.title}
              </p>
            )}
          </div>
            {data.techStack &&
              data.techStack.map(tech => (
                <div className="m-4" key={Math.random()}>
                  <h3 className="text-base leading-6 font-semibold text-shark-400">
                    {tech.title}
                  </h3>
                  <p
                    className="pt-2 text-sm text-shark-400"
                    dangerouslySetInnerHTML={{
                      __html: tech.excerpt,
                    }}
                  />
                </div>
              ))}
        </div>
      </div>
    )
  }
  return (
    <div className="bg-transparent mt-1">
      <div className="max-w-screen-xl mx-auto py-12 sm:py-16 px-4 sm:px-6 lg:px-8 ">
        <div
          className="mx-auto text-center flex flex-col place-items-center"
          data-aos="fade-up"
        >
          {title && <H2 title={title} />}
          {title && theme && (
            <hr
              className="h-1 w-40 mt-5 border-0"
              style={{ backgroundColor: theme }}
            />
          )}
        </div>
        <div
          className="flex flex-wrap lg:mt-16 md:mt-12 mt-8"
          data-aos="fade-up"
        >
          <div className="lg:w-1/3 w-full ">
            {chunked[1].length > 0 &&
              chunked[1].map(data => (
                <div key={Math.random()}>{contentData(data)}</div>
              ))}
          </div>
          <div className="lg:w-1/3 w-full ">
            {chunked[2].length > 0 &&
              chunked[2].map(data => (
                <div key={Math.random()}>{contentData(data)}</div>
              ))}
          </div>
          <div className="lg:w-1/3 w-full ">
            {chunked[0].length > 0 &&
              chunked[0].map(data => (
                <div key={Math.random()}>{contentData(data)}</div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechStackCardBlock
